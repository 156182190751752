var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"OrderProductListItem bg-day-04dp dark:bg-night-04dp rounded-lg py-3 px-4 flex items-center justify-between",class:{
        'opacity-50 cursor-not-allowed': _vm.orderStatus === 'processing' && _vm.orderProduct.product !== _vm.product.id
            || _vm.orderStatus === 'success' && _vm.orderProduct.product !== _vm.product.id
            || _vm.tapAndGoEnabled && _vm.balance < _vm.slotData.price
            || _vm.machineStatus === 'unavailable'
            || _vm.machineStatus === 'busy'
            || _vm.showUnavailableMessage
    },on:{"click":_vm.toggleProductDetailDrawer}},[_c('div',{staticClass:"OrderProductListItem_Left flex items-center"},[_c('div',{staticClass:"OrderProductListItem_Image bg-white rounded"},[_c('img',{staticClass:"w-12 h-12 object-contain",attrs:{"src":_vm.product.image,"alt":_vm.product.name}})]),_c('div',{staticClass:"OrderProductListItem_ProductInfo ml-2"},[_c('span',{staticClass:"block font-medium"},[_vm._v(_vm._s(_vm.product.name))]),_c('span',{staticClass:"block text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis"},[_vm._v(_vm._s(_vm.currency(_vm.slotData.price)))])])]),(_vm.allowAdd)?_c('div',{staticClass:"OrderProductListItem_Right"},[(_vm.tapAndGoEnabled && _vm.balance > _vm.slotData.price)?_c('button',{staticClass:"OrderProductGridItem_TapAndGo p-2 rounded-full bg-primary-brand shadow-glow-brand-button",attrs:{"disabled":_vm.orderStatus === 'processing' || _vm.machineStatus === 'unavailable' || _vm.machineStatus === 'busy' || _vm.showUnavailableMessage},on:{"click":function($event){$event.stopPropagation();return _vm.orderTappedProduct({
                slot: _vm.slotData.slot,
                product: _vm.product.id,
                amount: 1,
            })}}},[(_vm.orderProduct && _vm.orderProduct.product === _vm.product.id && _vm.orderStatus === 'processing')?_c('AppIcon',{key:"tap-and-go-order-processing",staticClass:"text-white",attrs:{"icon-name":"processing","spin":true}}):(_vm.orderProduct && _vm.orderProduct.product === _vm.product.id && _vm.orderStatus === 'success')?_c('AppIcon',{key:"tap-and-go-order-success",staticClass:"text-white",attrs:{"icon-name":"check"}}):_c('AppIcon',{key:"tap-and-go-order",staticClass:"text-white",attrs:{"icon-name":"fast-buy"}})],1):_vm._e(),(!_vm.tapAndGoEnabled)?_c('button',{staticClass:"OrderProductGridItem_AddToCart p-2 rounded-full bg-primary-brand shadow-glow-brand-button",class:{ 'opacity-50 cursor-not-allowed': _vm.hasReachedMax },attrs:{"disabled":_vm.hasReachedMax || _vm.machineStatus === 'unavailable' || _vm.machineStatus === 'busy' || _vm.showUnavailableMessage},on:{"click":function($event){$event.stopPropagation();return _vm.addProductToCart(_vm.slotData.slot, _vm.product.id)}}},[_c('AppIcon',{staticClass:"text-white",attrs:{"icon-name":"plus"}})],1):_vm._e()]):_vm._e(),_c('portal',{attrs:{"to":"drawers"}},[(_vm.showProductDetailDrawer)?_c('ProductDetailDrawer',{attrs:{"slot-data":_vm.slotData,"product":_vm.product,"allow-add":_vm.allowAdd && !_vm.hasReachedMax},on:{"add-to-cart":function($event){return _vm.addProductToCart(_vm.slotData.slot, _vm.product.id)},"close":_vm.toggleProductDetailDrawer}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }