var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDrawer',{staticClass:"AuthResetPasswordDrawer",on:{"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"px-4"},[_c('header',{staticClass:"AuthResetPasswordDrawer_Header"},[_c('h1',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.hasResetToken ? _vm.$t('account.reset-password.heading-reset') : _vm.$t('account.reset-password.heading')))]),(!_vm.requestFormSubmitted)?_c('p',{staticClass:"text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis mb-1"},[_vm._v(" "+_vm._s(_vm.hasResetToken ? _vm.$t('account.reset-password.description-reset') : _vm.$t('account.reset-password.description'))+" ")]):_vm._e()]),(!_vm.hasResetToken)?_c('ValidationObserver',{attrs:{"immediate":"","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(!_vm.requestFormSubmitted)?_c('form',{staticClass:"AuthResetPasswordDrawer_Form mt-4",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitRequestPasswordReset)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email","mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInputField',{attrs:{"type":"email","label":_vm.$t('account.reset-password.email'),"placeholder":"e.g. john@email.com","error":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('AppButton',{staticClass:"mt-6",attrs:{"size":"small","width":"full"}},[_vm._v(_vm._s(_vm.$t('account.reset-password.submit-button')))])],1):_vm._e(),(_vm.requestFormSubmitted)?_c('div',{staticClass:"bg-primary-brand p-4 rounded-lg my-8"},[_c('p',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.$t('account.reset-password.request-success-message')))])]):_vm._e()]}}],null,false,2441322634)}):_vm._e(),(_vm.hasResetToken)?_c('ValidationObserver',{attrs:{"immediate":"","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"AuthResetPasswordDrawer_Form mt-4 grid row-gap-4",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitPasswordReset)}}},[_c('ValidationProvider',{attrs:{"rules":"required|min:8","name":"confirm","mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInputField',{attrs:{"type":"password","allow-view-password":false,"label":((_vm.$t('account.create-account.password')) + " <span class='font-normal text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis'>" + (_vm.$t('account.create-account.minimal-chars')) + "</span>"),"placeholder":"•••••••••","error":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:8|password:@confirm","mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInputField',{attrs:{"type":"password","allow-view-password":false,"label":_vm.$t('account.reset-password.repeat-password'),"placeholder":"•••••••••","error":errors[0]},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}})]}}],null,true)}),_c('AppButton',{staticClass:"mt-6",attrs:{"size":"small","width":"full"}},[_vm._v(_vm._s(_vm.$t('account.reset-password.submit-button-reset')))])],1)]}}],null,false,4293438468)}):_vm._e(),(!_vm.hasResetToken && !_vm.requestFormSubmitted)?_c('footer',{staticClass:"AuthCreateAccountDrawer_Footer my-4 text-center"},[_c('p',{staticClass:"text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis"},[_vm._v(_vm._s(_vm.$t('account.reset-password.footer.did-not-forget'))+" "),_c('span',{staticClass:"underline cursor-pointer text-day-on-surface-high-emphasis dark:text-night-on-surface-high-emphasis",on:{"click":function($event){return _vm.$emit('show-login')}}},[_vm._v(_vm._s(_vm.$t('account.reset-password.footer.login')))])])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }