<template>
    <!-- eslint-disable max-len -->
    <table class="ProductNutritionalValuesList w-full relative">
        <tr @click="toggleTable">
            <th><h3>{{ $t('order.product-detail.nutritional-values') }}</h3></th>
            <!-- <th class="text-day-on-surface-low-emphasis-disabled dark:text-night-on-surface-low-emphasis-disabled font-normal">{{ $t('order.product-detail.per-100-g') }}</th> -->
            <th v-if="tableOpen" class="text-day-on-surface-medium-emphasis-disabled dark:text-night-on-surface-low-emphasis-disabled font-normal">{{ $t('order.product-detail.per-part') }}</th>
        </tr>

        <template v-if="tableOpen">
            <tr v-for="(value, key) in specifications" :key="key">
                <td>{{ key }}</td>
                <td>{{ value }}</td>
            </tr>
        </template>

        <AppIcon icon-name="chevron" class="ProductNutritionalValuesList_Toggle w-4 h-4 absolute pointer-events-none" :class="{ 'transform rotate-180': tableOpen }" />
    </table>
</template>

<script>
import AppIcon from '@/components/Interface/AppIcon.vue';

export default {
    name: 'ProductNutritionalValuesList',

    components: {
        AppIcon,
    },

    props: {
        specifications: Object,
    },

    data() {
        return {
            tableOpen: false,
        };
    },

    methods: {
        toggleTable() {
            this.tableOpen = !this.tableOpen;
        },
    },
};
</script>

<style lang="scss" scoped>
.ProductNutritionalValuesList {

    &_Toggle {
        top: 10px;
        right: 0;
    }

    tr {

        th, td {
            @apply py-2;
            font-size: theme('fontSize.sm');
            line-height: theme('lineHeight.5');
            text-align: left;
        }

        td {
            color: theme('colors.day-on-surface.high-emphasis');

            &:first-child {
                @apply rounded-l-full;
                @apply pl-4;
            }

            &:last-child {
                @apply rounded-r-full;
                @apply pr-4;
            }
        }

        &:nth-child(even) {
            background-color: theme('colors.day.04dp');
        }
    }
}

.mode-dark .ProductNutritionalValuesList {

    td {
        color: theme('colors.night-on-surface.high-emphasis');
    }

    tr:nth-child(even) {
        background-color: theme('colors.night.02dp');
    }
}
</style>
