var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDrawer',{staticClass:"AuthCreateAccountDrawer",on:{"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"px-4"},[_c('header',{staticClass:"AuthCreateAccountDrawer_Header"},[_c('h1',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('account.create-account.heading')))]),_c('p',{staticClass:"text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis mb-1",domProps:{"innerHTML":_vm._s(_vm.$t('account.create-account.description'))},on:{"click":function($event){return _vm.$emit('show-login')}}})]),_c('ValidationObserver',{attrs:{"immediate":"","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"AuthCreateAccountDrawer_Form mt-4 mb-8 grid row-gap-4",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitRegistration)}}},[_c('div',{staticClass:"grid grid-cols-2 col-gap-4"},[_c('ValidationProvider',{attrs:{"rules":"required|min:2","mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('AppInputField',{attrs:{"label":_vm.$t('account.create-account.firstname'),"placeholder":"e.g. John","error":errors[0],"valid":valid},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:3","mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('AppInputField',{attrs:{"label":_vm.$t('account.create-account.lastname'),"placeholder":"e.g. Doe","error":errors[0],"valid":valid},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}],null,true)})],1),_c('ValidationProvider',{attrs:{"rules":"required|email","mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('AppInputField',{attrs:{"type":"email","label":_vm.$t('account.create-account.email'),"placeholder":"e.g. john@email.com","error":errors[0],"valid":valid},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:8","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInputField',{attrs:{"type":"password","label":((_vm.$t('account.create-account.password')) + " <span class='font-normal text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis'>" + (_vm.$t('account.create-account.minimal-chars')) + "</span>"),"placeholder":"•••••••••","error":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('AppButton',{staticClass:"mt-6",attrs:{"size":"small","width":"full"}},[_vm._v(_vm._s(_vm.$t('account.create-account.submit-button')))])],1)]}}])}),_c('footer',{staticClass:"AuthCreateAccountDrawer_Footer my-4 text-center"},[_c('p',{staticClass:"text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis"},[_vm._v(_vm._s(_vm.$t('account.create-account.footer.continue-agree'))+" "),_c('RouterLink',{staticClass:"underline cursor-pointer text-day-on-surface-high-emphasis dark:text-night-on-surface-high-emphasis",attrs:{"to":{ name: 'TermsConditions' }}},[_vm._v(_vm._s(_vm.$t('account.create-account.footer.terms-conditions')))]),_vm._v(" "+_vm._s(_vm.$t('account.create-account.footer.and'))+" "),_c('RouterLink',{staticClass:"underline cursor-pointer text-day-on-surface-high-emphasis dark:text-night-on-surface-high-emphasis",attrs:{"to":{ name: 'PrivacyPolicy' }}},[_vm._v(_vm._s(_vm.$t('account.create-account.footer.privacy-policy')))])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }