<template>
    <!-- eslint-disable max-len -->
    <BaseDrawer @close="$emit('close')" class="ProductDetailDrawer">
        <div class="ProductDetailDrawer_Image px-16 pb-6 bg-white mx-4 mb-4 rounded">
            <img :src="product.image" :alt="product.name" class="ProductDetailDrawer_Image-product w-full" />
        </div>

        <article class="ProductDetailDrawer_Content p-4 bg-day-04dp dark:bg-night-04dp rounded-t-3xl pb-32 relative">
            <h1>{{ product.name }}</h1>

						<!-- Product price -->
						<div class="inline-flex items-center space-x-2">
							<span class="block font-medium text-base text-day-on-surface-ultra-emphasis dark:text-night-on-surface-ultra-emphasis">
								{{ slotData.price == 0 ? $t('order.free'): currency(slotData.price) }}
							</span>

							<span v-if="slotData.deposit && slotData.deposit > 0" class="text-day-on-surface-low-emphasis-disabled dark:text-night-on-surface-low-emphasis-disabled">
								{{ $t('order.product-detail.excluding-deposit', { value: currency(slotData.deposit) }) }}
							</span>
						</div>

						<!-- Badges -->
            <div v-if="product.allergies" class="ProductDetailDrawer_Details mt-4 flex flex-wrap">
                <AppBadge v-for="item in product.allergies" :key="item" class="bg-day-04dp dark:bg-night-on-surface mr-2 mb-2" size="medium">{{ item }}</AppBadge>
            </div>

            <div class="ProductDetailDrawer_Description mt-4">
                <p class="text-day-on-surface-high-emphasis dark:text-night-on-surface-high-emphasis">{{ product.description }}</p>
            </div>

            <div v-if="product.ingredients" class="ProductDetailDrawer_Ingredients mt-4">
                <header @click="toggleIngedients" class="flex items-center justify-between">
                    <h3>{{ $t('order.product-detail.ingredients') }}</h3>
                    <AppIcon icon-name="chevron" class="w-4 h-4" :class="{ 'transform rotate-180': ingredientsOpen }" />
                </header>

                <p v-if="ingredientsOpen" class="mt-1 text-day-on-surface-high-emphasis dark:text-night-on-surface-high-emphasis">{{ product.ingredients }}</p>
            </div>

            <ProductNutritionalValuesList v-if="product.specifications" :specifications="product.specifications" class="mt-4" />
        </article>

        <div class="ProductDetailDrawer_Buttons absolute z-10 grid grid-cols-2 gap-2">
            <AppButton @click="$emit('close')" type="secondary">
                Close
            </AppButton>

            <AppButton v-if="allowAdd && !tapAndGoEnabled && (machine.type === 'fridge' ? amount < 1 : true)" @click="addToCartAndClose" icon="shopping-cart" icon-position="left" type="primary" class="ml-2">
                Add to cart
            </AppButton>

            <slot v-if="amount > 0 && machine.type === 'fridge'" name="amount-selector" />
        </div>
    </BaseDrawer>
</template>

<script>
import BaseDrawer from '@/components/Interface/BaseDrawer.vue';
import AppBadge from '@/components/Interface/AppBadge.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import ProductNutritionalValuesList from '@/components/ProductNutritionalValuesList.vue';
import useFilters from '@/filters';
import useTapAndgo from '@/composables/useTapAndGo';
import useMachine from '@/composables/useMachine';
import { ref } from '@vue/composition-api';

export default {
    name: 'ProductDetailDrawer',

    components: {
        BaseDrawer,
        AppBadge,
        AppButton,
        AppIcon,
        ProductNutritionalValuesList,
    },

    props: {
        allowAdd: {
            type: Boolean,
            default: true,
        },
        product: {
            type: Object,
            required: true,
        },
        slotData: {
            type: Object,
            required: true,
        },

        amount: Number,
    },

    setup(props, { emit }) {
        const { currency } = useFilters();
        const { machine } = useMachine();
        const { enabled: tapAndGoEnabled } = useTapAndgo();

        /**
         * Method to add current product to cart and close the drawer
         * @returns {void}
         */
        const addToCartAndClose = () => {
            emit('add-to-cart');
            emit('close');
        };

        const ingredientsOpen = ref(false);

        const toggleIngedients = () => {
            ingredientsOpen.value = !ingredientsOpen.value;
        };

        return {
            currency,
            addToCartAndClose,
            ingredientsOpen,
            toggleIngedients,
            tapAndGoEnabled,
            machine,
        };
    },
};
</script>

<style lang="scss">
.ProductDetailDrawer {
    // max-height: 90vh;

    .ui-BaseDrawer_Inner {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        background-color: theme('colors.day.01dp');
    }

    &_Image img {
        max-height: 200px;
        object-fit: contain;
    }

    &_Content {
        max-height: 50vh;
        overflow-y: auto;
    }

    // &::after {
    //     content: '';
    //     position: absolute;
    //     bottom: 0;
    //     height: 120px;
    //     width: 100%;
    //     left: 0;
    //     right: 0;
    //     background: linear-gradient(0deg, #232323 0%, rgba(35, 35, 35, 0) 100%);
    //     pointer-events: none;
    // }

    &_Buttons {
        bottom: 24px;
        left: 16px;
        right: 16px;

        .ui-AppButton {
            @apply py-3 px-4;

            &:first-child {
                background-color: rgb(229 229 229);
            }
        }
    }
}

.mode-dark .ProductDetailDrawer {

    .ui-BaseDrawer_Inner {
        background-color: theme('colors.night.01dp');
    }

    &_Buttons {
        .ui-AppButton {
            &:first-child {
                background-color: theme('colors.night.12dp')
            }
        }
    }
}
</style>
