<template>
    <!-- eslint-disable max-len -->
    <BaseDrawer @close="$emit('close')" class="AuthCreateAccountDrawer">
        <div class="px-4">
            <header class="AuthCreateAccountDrawer_Header">
                <h1 class="mb-1">{{ $t('account.create-account.heading') }}</h1>
                <p @click="$emit('show-login')" v-html="$t('account.create-account.description')" class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis mb-1"></p>
            </header>

            <ValidationObserver v-slot="{ handleSubmit }" immediate slim>
                <form @submit.prevent="handleSubmit(submitRegistration)" novalidate class="AuthCreateAccountDrawer_Form mt-4 mb-8 grid row-gap-4">
                    <div class="grid grid-cols-2 col-gap-4">
                        <ValidationProvider rules="required|min:2" v-slot="{ errors, valid }" mode="eager" slim>
                            <AppInputField v-model="firstName" :label="$t('account.create-account.firstname')" placeholder="e.g. John" :error="errors[0]" :valid="valid" />
                        </ValidationProvider>

                        <ValidationProvider rules="required|min:3" v-slot="{ errors, valid }" mode="eager" slim>
                            <AppInputField v-model="lastName" :label="$t('account.create-account.lastname')" placeholder="e.g. Doe" :error="errors[0]" :valid="valid" />
                        </ValidationProvider>
                    </div>

                    <ValidationProvider rules="required|email" v-slot="{ errors, valid }" mode="eager" slim>
                        <AppInputField v-model="email" type="email" :label="$t('account.create-account.email')" placeholder="e.g. john@email.com" :error="errors[0]" :valid="valid" />
                    </ValidationProvider>

                    <ValidationProvider rules="required|min:8" v-slot="{ errors }" slim>
                        <AppInputField
                            v-model="password"
                            type="password"
                            :label="`${$t('account.create-account.password')} <span class='font-normal text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis'>${$t('account.create-account.minimal-chars')}</span>`"
                            placeholder="•••••••••"
                            :error="errors[0]" />
                    </ValidationProvider>

                    <AppButton size="small" width="full" class="mt-6">{{ $t('account.create-account.submit-button') }}</AppButton>
                </form>
            </ValidationObserver>

            <footer class="AuthCreateAccountDrawer_Footer my-4 text-center">
                <p class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ $t('account.create-account.footer.continue-agree') }} <RouterLink :to="{ name: 'TermsConditions' }" class="underline cursor-pointer text-day-on-surface-high-emphasis dark:text-night-on-surface-high-emphasis">{{ $t('account.create-account.footer.terms-conditions') }}</RouterLink> {{ $t('account.create-account.footer.and') }} <RouterLink :to="{ name: 'PrivacyPolicy' }" class="underline cursor-pointer text-day-on-surface-high-emphasis dark:text-night-on-surface-high-emphasis">{{ $t('account.create-account.footer.privacy-policy') }}</RouterLink></p>
            </footer>
        </div>
    </BaseDrawer>
</template>

<script>
/* eslint-disable max-len  */
import BaseDrawer from '@/components/Interface/BaseDrawer.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import AppInputField from '@/components/Interface/AppInputField.vue';
import { reactive, toRefs } from '@vue/composition-api';
import { UserService, AuthenticationError } from '@/services/user.service';
import router from '@/router';

export default {
    name: 'AuthCreateAccountDrawer',

    components: {
        BaseDrawer,
        AppButton,
        AppInputField,
    },

    setup(_, { root }) {
        const newAccountData = reactive({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
        });

        /**
         * Method to handle the registration submit
         * @returns {void}
         */
        const submitRegistration = async () => {
            try {
                const user = await UserService.createUser(newAccountData);

                if (user) {
                    router.push({ name: 'Account' });
                }

                return true;
            } catch (error) {
                if (error instanceof AuthenticationError) {
                    let errorMessage = root.$t('account.create-account.default-error');

                    // If there are server errors get the first one
                    if (error.message.errors[Object.keys(error.message.errors)[0]]) {
                        const [firstErrorMessage] = error.message.errors[Object.keys(error.message.errors)[0]];
                        errorMessage = firstErrorMessage;
                    }

                    // Show error notification to user
                    root.$toast.open({
                        message: errorMessage,
                        type: 'warning',
                        duration: 8000,
                    });
                }

                return false;
            }
        };

        return {
            ...toRefs(newAccountData),
            submitRegistration,
        };
    },
};
</script>

<style lang="scss" scoped>
.AuthCreateAccountDrawer {}
</style>
