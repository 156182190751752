<template>
    <!-- eslint-disable max-len -->
    <BaseDrawer @close="$emit('close')" class="AuthResetPasswordDrawer">
        <div class="px-4">
            <header class="AuthResetPasswordDrawer_Header">
                <h1 class="mb-1">{{ hasResetToken ? $t('account.reset-password.heading-reset') : $t('account.reset-password.heading') }}</h1>
                <p v-if="!requestFormSubmitted" class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis mb-1">
                    {{ hasResetToken ? $t('account.reset-password.description-reset') : $t('account.reset-password.description') }}
                </p>
            </header>

            <ValidationObserver v-if="!hasResetToken" v-slot="{ handleSubmit }" immediate slim>
                <form v-if="!requestFormSubmitted" @submit.prevent="handleSubmit(submitRequestPasswordReset)" class="AuthResetPasswordDrawer_Form mt-4" novalidate>
                    <ValidationProvider rules="required|email" v-slot="{ errors }" mode="eager" slim>
                        <AppInputField v-model="email" type="email" :label="$t('account.reset-password.email')" placeholder="e.g. john@email.com" :error="errors[0]" />
                    </ValidationProvider>

                    <AppButton size="small" width="full" class="mt-6">{{ $t('account.reset-password.submit-button') }}</AppButton>
                </form>

                 <div v-if="requestFormSubmitted" class="bg-primary-brand p-4 rounded-lg my-8">
                    <p class="text-white">{{ $t('account.reset-password.request-success-message') }}</p>
                </div>
            </ValidationObserver>

            <ValidationObserver v-if="hasResetToken" v-slot="{ handleSubmit }" immediate slim>
                <form @submit.prevent="handleSubmit(submitPasswordReset)" class="AuthResetPasswordDrawer_Form mt-4 grid row-gap-4" novalidate>
                    <ValidationProvider rules="required|min:8" name="confirm" v-slot="{ errors }" mode="eager" slim>
                        <AppInputField
                            v-model="password"
                            type="password"
                            :allow-view-password="false"
                            :label="`${$t('account.create-account.password')} <span class='font-normal text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis'>${$t('account.create-account.minimal-chars')}</span>`"
                            placeholder="•••••••••"
                            :error="errors[0]" />
                    </ValidationProvider>

                    <ValidationProvider rules="required|min:8|password:@confirm" v-slot="{ errors }" mode="eager" slim>
                        <AppInputField
                            v-model="confirmation"
                            type="password"
                            :allow-view-password="false"
                            :label="$t('account.reset-password.repeat-password')"
                            placeholder="•••••••••"
                            :error="errors[0]" />
                    </ValidationProvider>

                    <AppButton size="small" width="full" class="mt-6">{{ $t('account.reset-password.submit-button-reset') }}</AppButton>
                </form>
            </ValidationObserver>

            <footer v-if="!hasResetToken && !requestFormSubmitted" class="AuthCreateAccountDrawer_Footer my-4 text-center">
                <p class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ $t('account.reset-password.footer.did-not-forget') }} <span @click="$emit('show-login')" class="underline cursor-pointer text-day-on-surface-high-emphasis dark:text-night-on-surface-high-emphasis">{{ $t('account.reset-password.footer.login') }}</span></p>
            </footer>
        </div>
    </BaseDrawer>
</template>

<script>
/* eslint-disable max-len  */
import BaseDrawer from '@/components/Interface/BaseDrawer.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import AppInputField from '@/components/Interface/AppInputField.vue';
import { reactive, ref, toRefs } from '@vue/composition-api';
import { UserService, AuthenticationError } from '@/services/user.service';

export default {
    name: 'AuthResetPasswordDrawer',

    components: {
        BaseDrawer,
        AppButton,
        AppInputField,
    },

    setup(_, { root, emit }) {
        const hasResetToken = ref(false);

        if (root.$route.query.token) {
            hasResetToken.value = true;
        }

        const email = ref(null);

        const newPassword = reactive({
            password: '',
            confirmation: '',
        });

        const requestFormSubmitted = ref(false);

        /**
         * Method to handle the request reset password submit
         * @returns {void}
         */
        const submitRequestPasswordReset = async () => {
            try {
                const response = await UserService.requestResetPassword(email.value);
                requestFormSubmitted.value = true;

                return response;
            } catch (error) {
                if (error instanceof AuthenticationError) {
                    requestFormSubmitted.value = true;
                }

                return false;
            }
        };

        /**
         * Method to handle the reset password submit
         * @returns {void}
         */
        const submitPasswordReset = async () => {
            try {
                const response = await UserService.resetPassword(newPassword.password, newPassword.confirmation);

                root.$toast.open({
                    message: root.$t('account.reset-password.success-message'),
                    type: 'success',
                    duration: 8000,
                });

                // Show login form
                emit('close');
                emit('show-login');

                return response;
            } catch (error) {
                if (error instanceof AuthenticationError) {
                    let errorMessage = root.$t('account.reset-password.reset-error');

                    // If there are server errors get the first one
                    if (error.message && error.message.errors[Object.keys(error.message.errors)[0]]) {
                        const [firstErrorMessage] = error.message.errors[Object.keys(error.message.errors)[0]];
                        errorMessage = firstErrorMessage;
                    }

                    // Show error notification to user
                    root.$toast.open({
                        message: errorMessage,
                        type: 'warning',
                        duration: 8000,
                    });
                }

                return false;
            }
        };

        return {
            hasResetToken,
            email,
            submitRequestPasswordReset,
            ...toRefs(newPassword),
            submitPasswordReset,
            requestFormSubmitted,
        };
    },
};
</script>

<style lang="scss" scoped>
.AuthResetPasswordDrawer {}
</style>
