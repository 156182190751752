<template>
    <BaseDrawer @close="$emit('cancel')" class="CheckoutDrawer z-40">
        <!-- eslint-disable max-len -->
        <header class="CheckoutDrawer_Header text-center flex items-center justify-center">
            <svg v-if="creatingPayment" class="spinner" viewBox="0 0 100 100" width="20" height="20">
                <circle cx="50" cy="50" r="42" transform="rotate(-90,50,50)" />
            </svg>

            <h1 class="ml-2 flex items-center">{{ $t('checkout.payment') }}
							{{ currency(amount) }}
            </h1>
        </header>

        <div class="CheckoutDrawer_PaymentMethods mt-4 grid row-gap-2">

            <!-- Wallet -->
            <div v-if="allowJuneaWallet"
                @click="loggedIn && sufficientBalance ? selectedPaymentMethod = 'wallet' : null"
                class="py-3 px-4 rounded-3xl bg-day-04dp dark:bg-night-12dp flex items-center justify-between cursor-pointer"
                :class="{ 'CheckoutDrawer_WalletAllowed': loggedIn }">

                <div class="flex items-center">
                    <span v-if="loggedIn && sufficientBalance" class="CheckoutDrawer_ItemSelect-wallet mr-2" :class="{ 'active': selectedPaymentMethod === 'wallet' }"></span>
                    <AppIcon icon-name="junea-letter" :width="32" :height="32" />

                    <div class="ml-2">
                        <span class="block font-medium">Wallet</span>
                    </div>
                </div>

                <!-- Login button -->
                <RouterLink v-if="!loggedIn" :to="{ name: 'NoAccount' }" class="bg-day-on-primary dark:bg-night-on-surface rounded-full px-4 py-2 flex items-center">
                    <span class="leading-6">{{ $t('account.no-account.button-login') }}</span>
                    <AppIcon icon-name="arrow-right" :width="20" :height="20" />
                </RouterLink>

                <!-- Wallet Balance -->
                <div v-if="loggedIn" class="ml-2 px-3 py-2 rounded-full flex items-center bg-day-on-surface-low-emphasis-disabled" :class="{ 'opacity-50': selectedPaymentMethod !== 'wallet' || !sufficientBalance }">
                    <AppIcon icon-name="wallet" :width="16" :height="16" class="text-white" />
                    <span class="ml-1">{{ currency(walletBalance) }}</span>
                </div>
            </div>

            <!-- iDEAL -->
            <div @click="selectedPaymentMethod = 'ideal'" class="py-4 px-4 rounded-3xl bg-day-04dp dark:bg-night-12dp flex items-center justify-between cursor-pointer">
                <div class="flex items-center mr-6">
                    <span class="CheckoutDrawer_ItemSelect-default mr-2" :class="{ 'active': selectedPaymentMethod === 'ideal' }"><span></span></span>
                    <AppIcon icon-name="ideal" :width="32" :height="32" />

                    <div class="ml-2">
                        <span class="block font-medium">iDEAL</span>
                    </div>
                </div>

                <!-- Bank select -->
                <div class="CheckoutDrawer_SelectWrapper w-full" :class="{ 'pointer-events-none opacity-50': selectedPaymentMethod !== 'ideal' }">
                    <select v-model="selectedIdealBank" :disabled="!idealBanks || idealBanks.length == 0" class="CheckoutDrawer_BankSelect w-full appearance-none px-4 py-2 rounded-lg bg-day-on-primary dark:bg-night-on-surface dark:text-white"
                        :class="{ 'border-2 border-secondary-error': bankSelectInvalid }">
                        <option disabled selected value="">{{ $t('checkout.select-bank') }}</option>
                        <option v-for="bank in idealBanks" :key="bank.id" :value="bank.id">{{ bank.name }}</option>
                    </select>
                </div>
            </div>

            <!-- Apple Pay -->
            <div v-if="applePayEnabled" @click="selectedPaymentMethod = 'apple-pay'" class="py-4 px-4 rounded-3xl bg-day-04dp dark:bg-night-12dp flex items-center justify-between cursor-pointer">
                <div class="flex items-center mr-6">
                    <span class="CheckoutDrawer_ItemSelect-default mr-2" :class="{ 'active': selectedPaymentMethod === 'apple-pay' }"><span></span></span>
                    <AppIcon icon-name="apple-pay" :width="32" :height="32" />

                    <div class="ml-2">
                        <span class="block font-medium">Apple Pay</span>
                    </div>
                </div>
            </div>

            <!-- Creditcard -->
            <div v-if="creditCardEnabled" @click="selectedPaymentMethod = 'credit-card'" class="py-4 px-4 rounded-3xl bg-day-04dp dark:bg-night-12dp flex items-center justify-between cursor-pointer">
                <div class="flex items-center mr-6">
                    <span class="CheckoutDrawer_ItemSelect-default mr-2" :class="{ 'active': selectedPaymentMethod === 'credit-card' }"><span></span></span>
                    <AppIcon icon-name="creditcard" :width="32" :height="32" />

                    <div class="ml-2">
                        <span class="block font-medium">Creditcard</span>
                    </div>
                </div>
            </div>

            <!-- Bankcontact  -->
            <div v-if="bancontactEnabled" @click="selectedPaymentMethod = 'bancontact'" class="py-4 px-4 rounded-3xl bg-day-04dp dark:bg-night-12dp flex items-center justify-between cursor-pointer">
                <div class="flex items-center mr-6">
                    <span class="CheckoutDrawer_ItemSelect-default mr-2" :class="{ 'active': selectedPaymentMethod === 'bancontact' }"><span></span></span>
                    <AppIcon icon-name="bancontact" :width="32" :height="32" />

                    <div class="ml-2">
                        <span class="block font-medium">Bancontact</span>
                    </div>
                </div>
            </div>
        </div>

        <AppButton @click="initializePayment" width="full" class="mt-4">{{ $t('checkout.pay-with') }}
            <span v-if="selectedPaymentMethod === 'wallet'">Wallet</span>
            <span v-if="selectedPaymentMethod === 'ideal'">iDEAL</span>
            <span v-if="selectedPaymentMethod === 'apple-pay'">Apple Pay</span>
            <span v-if="selectedPaymentMethod === 'credit-card'">Creditcard</span>
            <span v-if="selectedPaymentMethod === 'bancontact'">Bancontact</span>
        </AppButton>
    </BaseDrawer>
</template>

<script>
/* eslint-disable object-curly-newline */
import BaseDrawer from '@/components/Interface/BaseDrawer.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import useFilters from '@/filters';
import useAuth from '@/composables/useAuth';
import useWallet from '@/composables/useWallet';
import useOrder from '@/composables/useOrder';
import useMachine from '@/composables/useMachine';
import { onBeforeMount, reactive, toRefs } from '@vue/composition-api';

export default {
    name: 'CheckoutDrawer',

    components: {
        BaseDrawer,
        AppIcon,
        AppButton,
    },

    props: {
        amount: Number,
        allowJuneaWallet: {
            type: Boolean,
            default: true,
        },
        isTopupPayment: Boolean,
    },

    setup(props, { root, emit }) {
        const { currency } = useFilters();
        const { loggedIn } = useAuth();
        const { getWalletBalance } = useWallet();
        const { idealBanks } = useOrder();
        const { machine } = useMachine();

        const paymentData = reactive({
            selectedPaymentMethod: null,
            selectedIdealBank: '',
            sufficientBalance: false,
            walletBalance: 0,
            creatingPayment: false,
            bankSelectInvalid: false,
            applePayEnabled: false,
            creditCardEnabled: false,
            bancontactEnabled: false,
        });

        const setDefaultPaymentMethod = async () => {
            if (loggedIn.value && props.allowJuneaWallet) {
                paymentData.walletBalance = await getWalletBalance();

                if (paymentData.walletBalance > 0 && props.amount <= paymentData.walletBalance) {
                    paymentData.selectedPaymentMethod = 'wallet';
                    paymentData.sufficientBalance = true;
                } else {
                    paymentData.selectedPaymentMethod = 'ideal';
                    paymentData.sufficientBalance = false;
                }
            } else {
                paymentData.selectedPaymentMethod = 'ideal';
            }
        };

        onBeforeMount(async () => {
            setDefaultPaymentMethod();

            try {
                const fetchUrl = `/payment-methods/${props.isTopupPayment ? 'wallet' : machine.value.code}`;
                const paymentMethodsResponse = await root.$http.get(fetchUrl);

                [idealBanks.value] = paymentMethodsResponse.data.ideal;
                paymentData.creditCardEnabled = paymentMethodsResponse.data?.creditcard?.enabled;

                if (window.ApplePaySession && paymentMethodsResponse.data['apple-pay']?.enabled) {
                    paymentData.applePayEnabled = true;
                }

                if (paymentMethodsResponse.data.bancontact?.enabled) {
                    paymentData.bancontactEnabled = true;
                }
            } catch (error) {
                console.log(error);
            }
        });

        // const selectedPaymentMethod = ref(null);

        const initializePayment = () => {
            paymentData.bankSelectInvalid = false;

            if (paymentData.selectedPaymentMethod === 'ideal' && !paymentData.selectedIdealBank) {
                paymentData.bankSelectInvalid = true;
                return;
            }

            paymentData.creatingPayment = true;
            emit('payment-method-picked', paymentData.selectedPaymentMethod);

            if (paymentData.selectedPaymentMethod === 'ideal') {
                emit('ideal-bank-selected', paymentData.selectedIdealBank);
            }
        };

        return {
            currency,
            loggedIn,
            ...toRefs(paymentData),
            initializePayment,
            idealBanks,
        };
    },
};
</script>

<style lang="scss">
.CheckoutDrawer {

    .ui-BaseDrawer_Inner {
        background-color: theme('colors.day.01dp');
    }

    &_Header {

        &-discount::after {
            position: absolute;
            content: '';
            width: 100%;
            height: 1.5px;
            background-color: theme('colors.primary.brand');
            left: 0;
            right: 0;
            top: 50%;
            bottom: 0;
            box-shadow: 0px 1px 0px rgba(39, 39, 39, 0.8);
            transform: rotate(-14.44deg);
        }

        @-webkit-keyframes spin {
            to { stroke-dashoffset: -264; }
        }

        @keyframes spin {
            to { stroke-dashoffset: -264; }
        }

        .spinner circle {
            fill: none;
            stroke: #1a1a1a;
            stroke-width: 16;
            stroke-linecap: round;
            stroke-dasharray: 0, 0, 70, 194;
            stroke-dashoffset: 0;
            animation: spin 1.4s infinite linear;
            -webkit-animation: spin 1.4s infinite linear;
        }
    }

    &_WalletAllowed {
        background: theme('colors.primary.brand') !important;

        * {
            color: white;
        }
    }

    &_ItemSelect {

        &-default, &-wallet {
            height: 24px;
            width: 24px;
            filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08));
            border-radius: 9999px;
            flex-shrink: 0;
        }

        &-default {
            border: 1.5px solid rgba(0, 0, 0, 0.12);

            &.active {
                border: 1.5px solid rgba(237, 74, 104, 0.24);
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    display: block;
                    width: 16px;
                    height: 16px;
                    background: theme('colors.primary.brand');
                    border-radius: 9999px;
                }
            }
        }

        &-wallet {
            border: 1.5px solid rgba(255, 255, 255, 0.38);

            &.active {
                border: 5px solid #FFFFFF;

            }
        }
    }

    &_SelectWrapper {
        position: relative;

        &::after {
            content: '';
            @apply absolute pointer-events-none bg-center bg-no-repeat;
            right: 0.5rem;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.83342 11.0758C5.98955 10.9206 6.20076 10.8335 6.42092 10.8335C6.64107 10.8335 6.85228 10.9206 7.00842 11.0758L10.0001 14.0258L12.9501 11.0758C13.1062 10.9206 13.3174 10.8335 13.5376 10.8335C13.7577 10.8335 13.9689 10.9206 14.1251 11.0758C14.2032 11.1533 14.2652 11.2455 14.3075 11.347C14.3498 11.4486 14.3716 11.5575 14.3716 11.6675C14.3716 11.7775 14.3498 11.8864 14.3075 11.988C14.2652 12.0895 14.2032 12.1817 14.1251 12.2592L10.5918 15.7925C10.5143 15.8706 10.4221 15.9326 10.3206 15.9749C10.219 16.0172 10.1101 16.039 10.0001 16.039C9.89007 16.039 9.78115 16.0172 9.6796 15.9749C9.57805 15.9326 9.48589 15.8706 9.40842 15.7925L5.83342 12.2592C5.75531 12.1817 5.69332 12.0895 5.65101 11.988C5.6087 11.8864 5.58692 11.7775 5.58692 11.6675C5.58692 11.5575 5.6087 11.4486 5.65101 11.347C5.69332 11.2455 5.75531 11.1533 5.83342 11.0758Z' fill='%23121212'/%3E%3Cpath d='M5.83342 8.29674C5.98955 8.45194 6.20076 8.53906 6.42092 8.53906C6.64107 8.53906 6.85228 8.45194 7.00842 8.29674L10.0001 5.34674L12.9501 8.29674C13.1062 8.45194 13.3174 8.53906 13.5376 8.53906C13.7577 8.53906 13.9689 8.45194 14.1251 8.29674C14.2032 8.21927 14.2652 8.1271 14.3075 8.02555C14.3498 7.924 14.3716 7.81508 14.3716 7.70507C14.3716 7.59506 14.3498 7.48614 14.3075 7.38459C14.2652 7.28304 14.2032 7.19087 14.1251 7.1134L10.5918 3.58007C10.5143 3.50196 10.4221 3.43997 10.3206 3.39766C10.219 3.35535 10.1101 3.33357 10.0001 3.33357C9.89007 3.33357 9.78115 3.35535 9.6796 3.39766C9.57805 3.43997 9.48589 3.50196 9.40842 3.58007L5.83342 7.1134C5.75531 7.19087 5.69332 7.28304 5.65101 7.38459C5.6087 7.48614 5.58692 7.59506 5.58692 7.70507C5.58692 7.81508 5.6087 7.924 5.65101 8.02555C5.69332 8.1271 5.75531 8.21927 5.83342 8.29674Z' fill='%23121212'/%3E%3C/svg%3E%0A");
        }
    }
}

.mode-dark .CheckoutDrawer {

    .ui-BaseDrawer_Inner {
        background-color: theme('colors.night.04dp');
    }

    &_Header {
        .spinner circle {
            stroke: white;
        }
    }

    &_ItemSelect {

        &-default {
            border: 1.5px solid rgba(255, 255, 255, 0.12);
        }
    }

    &_SelectWrapper {

        &::after {
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.83342 11.0758C5.98955 10.9206 6.20076 10.8335 6.42092 10.8335C6.64107 10.8335 6.85228 10.9206 7.00842 11.0758L10.0001 14.0258L12.9501 11.0758C13.1062 10.9206 13.3174 10.8335 13.5376 10.8335C13.7577 10.8335 13.9689 10.9206 14.1251 11.0758C14.2032 11.1533 14.2652 11.2455 14.3075 11.347C14.3498 11.4486 14.3716 11.5575 14.3716 11.6675C14.3716 11.7775 14.3498 11.8864 14.3075 11.988C14.2652 12.0895 14.2032 12.1817 14.1251 12.2592L10.5918 15.7925C10.5143 15.8706 10.4221 15.9326 10.3206 15.9749C10.219 16.0172 10.1101 16.039 10.0001 16.039C9.89007 16.039 9.78115 16.0172 9.6796 15.9749C9.57805 15.9326 9.48589 15.8706 9.40842 15.7925L5.83342 12.2592C5.75531 12.1817 5.69332 12.0895 5.65101 11.988C5.6087 11.8864 5.58692 11.7775 5.58692 11.6675C5.58692 11.5575 5.6087 11.4486 5.65101 11.347C5.69332 11.2455 5.75531 11.1533 5.83342 11.0758Z' fill='white' fill-opacity='0.38'/%3E%3Cpath d='M5.83342 8.29674C5.98955 8.45194 6.20076 8.53906 6.42092 8.53906C6.64107 8.53906 6.85228 8.45194 7.00842 8.29674L10.0001 5.34674L12.9501 8.29674C13.1062 8.45194 13.3174 8.53906 13.5376 8.53906C13.7577 8.53906 13.9689 8.45194 14.1251 8.29674C14.2032 8.21927 14.2652 8.1271 14.3075 8.02555C14.3498 7.924 14.3716 7.81508 14.3716 7.70507C14.3716 7.59506 14.3498 7.48614 14.3075 7.38459C14.2652 7.28304 14.2032 7.19087 14.1251 7.1134L10.5918 3.58007C10.5143 3.50196 10.4221 3.43997 10.3206 3.39766C10.219 3.35535 10.1101 3.33357 10.0001 3.33357C9.89007 3.33357 9.78115 3.35535 9.6796 3.39766C9.57805 3.43997 9.48589 3.50196 9.40842 3.58007L5.83342 7.1134C5.75531 7.19087 5.69332 7.28304 5.65101 7.38459C5.6087 7.48614 5.58692 7.59506 5.58692 7.70507C5.58692 7.81508 5.6087 7.924 5.65101 8.02555C5.69332 8.1271 5.75531 8.21927 5.83342 8.29674Z' fill='white' fill-opacity='0.38'/%3E%3C/svg%3E%0A");
        }
    }
}
</style>
