<template>
    <!-- eslint-disable max-len -->
    <div class="ui-AppInputField">
        <label for="" class="block mb-1 text-left font-medium text-sm leading-5" v-html="label"></label>

        <div class="relative">
            <input
                :type="viewPassword ? 'text' : type"
                :pattern="mask ? '[0-9]*' : ''"
                :value="value"
                @input="$emit('input', $event.target.value)"
                @focus="focusInputField"
                @blur="$emit('blur')"
                :placeholder="placeholder"
                :disabled="disabled"
                v-mask="mask"
                class="block w-full rounded-lg p-4 text-sm leading-5 dark:text-white placeholder:text-day-on-surface-medium-emphasis dark-placeholder:text-night-on-surface-medium-emphasis bg-day-04dp dark:bg-night-on-surface"
                :class="{
                    'border-2 border-secondary-error': error,
                    'opacity-50 cursor-not-allowed': disabled,
                }" />

            <div class="ui-AppInputField_Icon absolute">
                <AppIcon
                    v-if="valid"
                    icon-name="checkmark"
                    class="text-progressbar-green"
                    key="input-valid"
                    :width="24" :height="24" />

                <AppIcon
                    v-if="error"
                    icon-name="error"
                    class="text-secondary-error"
                    key="input-error"
                    :width="24" :height="24" />

                <AppIcon
                    v-if="type === 'password' && allowViewPassword && !error"
                    @click="toggleViewPassword"
                    icon-name="eye"
                    key="input-password"
                    class="cursor-pointer"
                    :width="24" :height="24" />
            </div>
        </div>

        <span v-if="error" class="block mt-1 text-left text-sm leading-5 text-secondary-error">{{ error }}</span>
    </div>
</template>

<script>
import AppIcon from '@/components/Interface/AppIcon.vue';
import { ref } from '@vue/composition-api';

export default {
    name: 'UIAppInputField',

    components: {
        AppIcon,
    },

    props: {
        value: String,

        type: {
            type: String,
            default: 'text',
        },

        label: {
            type: String,
        },

        placeholder: {
            type: String,
            required: false,
        },

        error: String,
        valid: Boolean,
        disabled: {
            type: Boolean,
            default: false,
        },

        allowViewPassword: {
            type: Boolean,
            default: true,
        },

        mask: String,
    },

    setup(_, { emit }) {
        const viewPassword = ref(false);

        /**
         * Toggle view password field with eye icon
         * @returns {void}
         */
        const toggleViewPassword = () => {
            viewPassword.value = !viewPassword.value;
        };

        /**
         * Method to scroll to focussed input field
         * @returns {void}
         */
        const focusInputField = (event) => {
            event.target.scrollIntoView({
                behavior: 'smooth',
            });

            emit('focus');
        };

        return {
            viewPassword,
            toggleViewPassword,
            focusInputField,
        };
    },
};
</script>

<style lang="scss" scoped>
.ui-AppInputField {

    &_Icon {
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
    }

    input {
        &:focus, &:active {
            box-shadow: 0px 0px 0px 4px #ececec;
        }
    }
}

.mode-dark .ui-AppInputField {

    input {
        &:focus, &:active {
            box-shadow: 0px 0px 0px 4px #2C2C2C;
        }
    }
}
</style>
