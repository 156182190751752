<template>
    <!-- eslint-disable max-len -->
    <BaseDrawer @close="$emit('close')" class="AuthLoginDrawer">
        <div class="px-4">
            <header class="AuthLoginDrawer_Header">
                <h1 class="mb-1">{{ $t('account.login.heading') }}</h1>
                <p @click="$emit('show-register')" v-html="$t('account.login.description')" class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis mb-1"></p>
            </header>

            <ValidationObserver v-slot="{ handleSubmit }" immediate slim>
                <form @submit.prevent="handleSubmit(submitLogin)" novalidate class="AuthLoginDrawer_Form mt-4 grid row-gap-4">
                    <ValidationProvider rules="required|email" v-slot="{ errors }" mode="eager" slim>
                        <AppInputField v-model="email" type="email" :label="$t('account.login.email')" placeholder="e.g. john@email.com" :error="errors[0]" />
                    </ValidationProvider>

                    <ValidationProvider rules="required" v-slot="{ errors }" mode="eager" slim>
                        <AppInputField v-model="password" type="password" :label="$t('account.login.password')" placeholder="•••••••••" :error="errors[0]" />
                    </ValidationProvider>

                    <AppButton size="small" width="full" class="mt-6">{{ $t('account.login.submit-button') }}</AppButton>
                </form>
            </ValidationObserver>

            <footer class="AuthCreateAccountDrawer_Footer my-4 text-center">
                <span @click="$emit('forgot-password')" class="underline cursor-pointer text-day-on-surface-high-emphasis dark:text-night-on-surface-high-emphasis">{{ $t('account.login.footer.forgot-password') }} </span>
            </footer>
        </div>
    </BaseDrawer>
</template>

<script>
import BaseDrawer from '@/components/Interface/BaseDrawer.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import AppInputField from '@/components/Interface/AppInputField.vue';
import { reactive, toRefs } from '@vue/composition-api';
import useAuth from '@/composables/useAuth';

export default {
    name: 'AuthLoginDrawer',

    components: {
        BaseDrawer,
        AppButton,
        AppInputField,
    },

    setup(_, { root }) {
        const { login, authenticationError } = useAuth();

        const loginData = reactive({
            email: '',
            password: '',
        });

        /**
         * Method to handle the login submit
         * @return {void}
         */
        const submitLogin = async () => {
            await login({
                email: loginData.email,
                password: loginData.password,
            });

            // Show error notification to user
            if (authenticationError.value) {
                root.$toast.open({
                    message: root.$t('account.login.error'),
                    type: 'warning',
                    duration: 8000,
                });
            }
        };

        return {
            ...toRefs(loginData),
            submitLogin,
        };
    },
};
</script>

<style lang="scss" scoped>
.AuthLoginDrawer {}
</style>
