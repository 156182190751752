<template>
    <div class="ui-AppBadge rounded-full" :class="{
        'py-1 px-3 text-xs leading-4': size === 'default',
        'py-1 px-2': size === 'medium',
        'py-3 px-4': size === 'large',
    }">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'UIAppBadge',

    props: {
        size: {
            type: String,
            default: 'default',
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-AppBadge {}
</style>
