<template>
    <!-- eslint-disable max-len -->
    <div @click="toggleProductDetailDrawer" class="OrderProductListItem bg-day-04dp dark:bg-night-04dp rounded-lg py-3 px-4 flex items-center justify-between"
        :class="{
            'opacity-50 cursor-not-allowed': orderStatus === 'processing' && orderProduct.product !== product.id
                || orderStatus === 'success' && orderProduct.product !== product.id
                || tapAndGoEnabled && balance < slotData.price
                || machineStatus === 'unavailable'
                || machineStatus === 'busy'
                || showUnavailableMessage
        }">

        <div class="OrderProductListItem_Left flex items-center">
            <div class="OrderProductListItem_Image bg-white rounded">
                <img :src="product.image" :alt="product.name" class="w-12 h-12 object-contain" />
            </div>

            <div class="OrderProductListItem_ProductInfo ml-2">
                <span class="block font-medium">{{ product.name }}</span>
                <span class="block text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ currency(slotData.price) }}</span>
            </div>
        </div>

        <div v-if="allowAdd" class="OrderProductListItem_Right">

            <!-- Tap and go button -->
            <button
                v-if="tapAndGoEnabled && balance > slotData.price"
                @click.stop="orderTappedProduct({
                    slot: slotData.slot,
                    product: product.id,
                    amount: 1,
                })"
                :disabled="orderStatus === 'processing' || machineStatus === 'unavailable' || machineStatus === 'busy' || showUnavailableMessage"
                class="OrderProductGridItem_TapAndGo p-2 rounded-full bg-primary-brand shadow-glow-brand-button">

                <AppIcon v-if="orderProduct && orderProduct.product === product.id && orderStatus === 'processing'" icon-name="processing" :spin="true" class="text-white" key="tap-and-go-order-processing" />
                <AppIcon v-else-if="orderProduct && orderProduct.product === product.id && orderStatus === 'success'" icon-name="check" class="text-white" key="tap-and-go-order-success" />
                <AppIcon v-else icon-name="fast-buy" class="text-white" key="tap-and-go-order" />
            </button>

            <!-- Default add to cart button -->
            <button
                v-if="!tapAndGoEnabled"
                @click.stop="addProductToCart(slotData.slot, product.id)"
                :disabled="hasReachedMax || machineStatus === 'unavailable' || machineStatus === 'busy' || showUnavailableMessage"
                :class="{ 'opacity-50 cursor-not-allowed': hasReachedMax }"
                class="OrderProductGridItem_AddToCart p-2 rounded-full bg-primary-brand shadow-glow-brand-button">

                <AppIcon icon-name="plus" class="text-white" />
            </button>
        </div>

        <portal to="drawers">
            <ProductDetailDrawer
                v-if="showProductDetailDrawer"
                :slot-data="slotData"
                :product="product"
                @add-to-cart="addProductToCart(slotData.slot, product.id)"
                @close="toggleProductDetailDrawer"
                :allow-add="allowAdd && !hasReachedMax" />
        </portal>
    </div>
</template>

<script>
import ProductDetailDrawer from '@/components/Drawers/ProductDetail.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import { ref, computed } from '@vue/composition-api';
import useOrder from '@/composables/useOrder';
import useFilters from '@/filters';
import useWallet from '@/composables/useWallet';
import useTapAndgo from '@/composables/useTapAndGo';
import useMachine from '@/composables/useMachine';

export default {
    name: 'OrderProductListItem',

    props: {
        allowAdd: {
            type: Boolean,
            default: true,
        },
        slotData: Object,
        product: Object,
    },

    components: {
        ProductDetailDrawer,
        AppIcon,
    },

    setup(props) {
        const { machineStatus, showUnavailableMessage } = useMachine();
        const { cart, addProductToCart } = useOrder();
        const { currency } = useFilters();
        const { balance } = useWallet();
        const {
            enabled: tapAndGoEnabled,
            orderTappedProduct,
            orderStatus,
            orderProduct,
        } = useTapAndgo();

        const showProductDetailDrawer = ref(false);

        /**
         * Method to toggle the product detail drawer
         * @returns {void}
         */
        const toggleProductDetailDrawer = () => {
            showProductDetailDrawer.value = !showProductDetailDrawer.value;
        };

        /**
         * Computed property to check if cart amount has reached stock limit
         * @returns {Boolean}
         */
        const hasReachedMax = computed(() => {
            if (!cart.value || cart.value.length === 0) return false;

            const cartItem = cart.value.find((item) => item.slot === props.slotData.slot);

            // Check item with same slot ID is present in cart
            if (cartItem) {
                return cartItem.amount >= props.slotData.stock;
            }

            return false;
        });

        return {
            currency,
            addProductToCart,
            showProductDetailDrawer,
            toggleProductDetailDrawer,
            hasReachedMax,
            tapAndGoEnabled,
            orderTappedProduct,
            orderStatus,
            orderProduct,
            balance,
            machineStatus,
            showUnavailableMessage,
        };
    },
};
</script>

